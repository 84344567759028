import React from "react";
import { SelectPicker, CheckPicker } from "rsuite";
import { formCfg } from "../config";
import { playFetch } from "../../../lib/playfetch";

const QueryPicker = ({...props}) => {
    const [queryData, setQueryData] = React.useState([]);

    const queryChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange(value, props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const queryCleanHandler = (value, event) => {
        props.onChange(null, props.name);
    };

    React.useEffect(() => {
        const formData = new FormData();
        formData.append("dbquery", props.queryInput.split('/')[0])

        const apiUrl = `core/dbquery/readonly/prod-winter-core`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                const options = data.map((item) => {
                    return { value: item[props.queryInput.split('/')[1]], label: item[props.queryInput.split('/')[1]] }
                })
                setQueryData(options)
            });
    }, [props.queryInput]);

    return (
        <>
            {(props.multiPick === false) &&
            <SelectPicker
                data={queryData}
                value={props.value}
                disabled={props.disabled}
                plaintext={props.plaintext}
                onChange={queryChangeHandler}
                onClean={queryCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
            {(props.multiPick === true) &&
            <CheckPicker
                data={queryData}
                value={props.value}
                disabled={props.disabled}
                plaintext={props.plaintext}
                onChange={queryChangeHandler}
                onClean={queryCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
        </>
    );
};

export default QueryPicker;
