import { find } from "lodash";

export const isProdEnv = (name, environment) => environment.filter((env) => {
    if (env.label === name && (env.type === 'LIVE' || env.type === 'PROD')) {
       return true
    } else if (name === 'prod-winter-core' && env.label === 'prod-winter' && (env.type === 'LIVE' || env.type === 'PROD')) {
      /*
       * HACK:: As wincore project names doesn't align with the standard core envs, 
       * so it needs special handling.
       */
      return true
    } else {
      return false
    }
  }).filter(Boolean).length > 0;

export const isReadOnlyEnv = (name, environment) => {
    const env = find(environment, ['label', name])
    if (env === undefined) {
        return false
    } else {
        return false
    }
}
