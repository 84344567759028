import React from "react";
import { Stack, Panel, FlexboxGrid, Badge } from 'rsuite';
import Env from "./env";
import Status from "./status";
import { getGroupColour } from "./utils";

const Group = ({...props}) => {
	const header = <FlexboxGrid justify="start" align="middle">
						<FlexboxGrid.Item colspan={14}>
							<div style={{ borderBottom: "1px solid gray",
										  borderRadius: "20px" }}
							>
								<Status
									key={`grp-${props.name}`}
									loading={false}
									status={props.status.overall}
									size='1.5em' />&nbsp;&nbsp;
									<Badge color={(props.status.synch === true) ? "green" : "red"} >
										<div style={{ marginRight: "10px" }}>
											{props.name}
										</div>
									</Badge>
							</div>
						</FlexboxGrid.Item>
					</FlexboxGrid>

	return (
		<Panel header={header} style={{ backgroundColor: getGroupColour(props.name) }}>
            <Stack wrap justifyContent="flex-start" alignItems="flex-start" spacing="12px" >
				{props.envs.map((item, index) =>
								<Env
									group={item.grp_name}
									name={item.name}
									status={item.status}
                                    isLive={item.is_live}
									readOnly={props.readOnly}
									apps={item.applications}
									key={`env-${item}-${index}`}
									rawData={props.rawData}
								/>)}
            </Stack>
		</Panel>
	);
};

export default Group;