import React, { useEffect, useState } from "react";
import { Drawer, Form, Panel, Radio, RadioGroup, Button, Toggle, Schema } from 'rsuite';
import omit from 'lodash/omit';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import { playFetch } from "../../../../lib/playfetch";
import { fetch } from "../../Common/lib/fetch";
import { recordsCfg } from "../../config";
import Display from "../../Common/display";
import Apply from "./apply";
import Timestamp from "../../../FormGroup/Timestamp";
import { environments } from "../../../../config/environments";
import { formCfg } from "../../../FormGroup/config";
import { commonCfg } from "../../Common/config";
import { envType } from "../../../FormGroup/config";

const eventType = {
    RACEID: "raceid",
    MATCHID: "matchid",
    COMPETITIONID: "competitionid"
};

const Create = ({...props}) => {
    const [wincoreId, setWincoreId] = useState("");
    const [wincoreIdType, setWincoreIdType] = useState(eventType.RACEID);
    const [externalRef, setExternalRef] = useState(undefined);
    const [inputData, setInputData] = useState({});
    const [genData, setGenData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let nextRecord = {}
        props.keys.forEach(key => {
            switch(key.name) {
                case "url":
                case "event_id":
                case "competition_id":
                case "venue_name":
                case "race_number":
                case "match_name":
                case "competition_name":
                    break;
                case "start_date":
                case "end_date":
                    nextRecord[key.name] = null;
                    break;
                case "top_level":
                    nextRecord[key.name] = false;
                    break;
                default:
                    nextRecord[key.name] = "";
                    break;
            }
        })
        setInputData(nextRecord)

        if (props.id === recordsCfg.TYPE.FEATUREDRACE) {
            setWincoreIdType(eventType.RACEID)
        } else if (props.id === recordsCfg.TYPE.FEATUREDMATCH) {
            setWincoreIdType(eventType.MATCHID)
        }
    }, [props.keys, props.id]);

    const changeHandler = (value, name) => {
        setExternalRef(undefined);
        setGenData([])
        let newObj = inputData

        if (value === null) {
            switch (name) {
                case "start_date":
                case "end_date":
                    newObj = omit(newObj, [name]);
                    break;
                default:
                    newObj[name] = "";
                    break;
            }
        } else {
            newObj[name] = value;
        }
        setInputData({...newObj});
    }

    const wincoreIdChangeHandler = (value, name) => {
        setExternalRef(undefined);
        setGenData([])
        setWincoreId((value === null) ? "" : value)
    }

    const fetchFromApi = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/generate/${wincoreIdType}/${env}/${externalRef}`}
        })

        const response = await fetch(props.keys.map(key => key.name), requestData, false);

        const data = response.map((record) => {
            const name = record.children[0].name;
            const newRecord = {...record};
            newRecord["children"][0]["applied"] = false;

            if (props.id === recordsCfg.TYPE.QUICKLINKS) {
                const envObj = find(environments.core, { label: `${name.substring(0, name.lastIndexOf("-"))}` })
                newRecord["url"] = `https://${envObj.url}${record["url"]}`
            }

            Object.keys(inputData).forEach(key => {
                newRecord[key] = inputData[key]
            })
            return newRecord
        })
        return data;
    }

    const refreshData = async () => {
        let newData = [...genData]
        newData.forEach((el)=>{el.id = uniqueId();})
        setGenData([...newData]);
    }

    const fetchData = async (envName) => {
        if (externalRef === "") {
            return []
        }
        let newData = [...genData]
        if (genData.length === 0) {
            newData = await fetchFromApi(envName);
            setGenData([...newData]);
        } else {
            newData = newData.filter(el => el.children[0].applied === false)
            setGenData([...newData]);
        }
        return newData;
    }

    const fetchExternalRef = () => {
        const formData = new FormData();

        if (wincoreIdType === eventType.RACEID) {
            formData.append("dbquery", `select external_ref from race where id='${wincoreId}';`)
        } else if (wincoreIdType === eventType.MATCHID) {
            formData.append("dbquery", `select external_ref from match where id='${wincoreId}';`)
        } else if (wincoreIdType === eventType.COMPETITIONID) {
            formData.append("dbquery", `select external_ref from competition where id='${wincoreId}';`)
        }

        const apiUrl = `core/dbquery/readonly/prod-winter-core`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                if (isArray(data)) {
                    setExternalRef(data[0]["external_ref"]);
                } else {
                    setExternalRef("")
                }
            });
    }

    const generateDataHandler = (value, event) => {
        setGenData([])
        setExternalRef(undefined);
        fetchExternalRef();
    };

    const applyRecord = async (id) => {
        setLoading(true)
        const newData = [...genData]
        var index = newData.findIndex(obj => obj.id === id);
        newData[index]["children"][0]["applied"] = true
        setGenData([...newData])
        setLoading(false)
    }

    const renderApply = (envName, refetch, records) => {
        return <Apply
                    {...props}
                    keys={props.keys}
                    actionWidth={2}
                    refetchData={refetch}
                    envName={envName}
                    readOnly={true}
                    operation={commonCfg.OPERATIONS.APPLY}
                    record={records}
                    applyRecord={applyRecord}
                    cancelHandler={refreshData}
                />
    }

    const renderInput = key => {
        switch(key) {
            case "top_level":
                return (
                    <Form.Group controlId={key}>
                        <Form.ControlLabel><b>Dropdown:</b></Form.ControlLabel>
                        <Form.Control
                            name={key}
                            accepter={Toggle}
                            checked={get(inputData, key, false)}
                            onChange={(value, event) => changeHandler(value, key)}
                        />
                    </Form.Group>
                )
            case "start_date":
            case "end_date":
                return (
                    <Form.Group controlId={key} >
                        <Form.ControlLabel><b>{key}:</b></Form.ControlLabel>
                        <Form.Control
                            name={key}
                            accepter={Timestamp}
                            minDate={new Date()}
                            plaintext={props.readOnly}
                            value={get(inputData, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                        />
                    </Form.Group>
                );
            case "url":
            case "event_id":
            case "competition_id":
            case "race_number":
            case "venue_name":
            case "match_name":
            case "competition_name":
                return (
                    <></>
                );
            default:
                return (
                    <Form.Group controlId={key}>
                        <Form.ControlLabel><b>{key}:</b></Form.ControlLabel>
                        <Form.Control
                            name={key}
                            plaintext={props.readOnly}
                            value={get(inputData, key, "")}
                            onChange={(value, event) => changeHandler(value, key)}
                            rule={Schema.Types.StringType().isRequired('This field is required.')}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                        />
                    </Form.Group>
                );             
        }
    }

    const isSubmitDisabled = ((wincoreId.length === 0) ||
                                (inputData["order"].length === 0) ||
                                ((props.id === recordsCfg.TYPE.QUICKLINKS) && (inputData["name"].length === 0)))

    return (
        <>
            <Drawer
                open={props.open}
                onClose={props.onClose}
                size="full"
                placement="bottom"
                backdrop="static"
            >
				<Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        {props.title}
                    </Drawer.Title>
                </Drawer.Header>
				<Drawer.Body>
                    <Panel bordered>
                        <Form>
                            {(props.id === recordsCfg.TYPE.QUICKLINKS) && <Form.Group controlId="wincoreIdType">
                                <Form.ControlLabel><b>ID Type:</b></Form.ControlLabel>
                                <Form.Control
                                    name="wincoreIdType"
                                    accepter={RadioGroup}
                                    inline
                                    value={wincoreIdType}
                                    onChange={setWincoreIdType}
                                >
                                    <Radio value={eventType.RACEID}>{eventType.RACEID}</Radio>
                                    <Radio value={eventType.MATCHID}>{eventType.MATCHID}</Radio>
                                    <Radio value={eventType.COMPETITIONID}>{eventType.COMPETITIONID}</Radio>
                                </Form.Control>
                            </Form.Group>}
                            <Form.Group controlId="eventid">
                                <Form.ControlLabel><b>Prod Wincore ID:</b></Form.ControlLabel>
                                <Form.Control
                                    name="eventid"
                                    value={wincoreId}
                                    onChange={(value, event) => wincoreIdChangeHandler(value, event.target.name)}
                                    rule={Schema.Types.StringType().isRequired('This field is required.')}
                                    errorPlacement='rightEnd'
                                    style={{ width: formCfg.WIDTH }}
                                />
                            </Form.Group>
                            {(props.keys.map((key, keyIndex) => <React.Fragment key={`render-input-${keyIndex}`}>
                                {renderInput(key.name)}
                                </React.Fragment>))
                            }
                            <Button
                                appearance="primary"
                                disabled={isSubmitDisabled}
                                loading={loading}
                                onClick={generateDataHandler}
                            >
                                    Generate Data
                            </Button>
                        </Form>
                        {((isString(externalRef) === true) && (externalRef.length > 0) && (loading === false)) && <Display
                            displayEnv={true}
                            selectEnv={false}
                            id={props.id}
                            showEnvPickerAsTab={false}
                            envTypes={[envType.CORE]}
                            excludedEnvs={props.excludedEnvs}
                            colWidth={props.keys.map(key => key.withBookWidth)}
                            actionWidth={2}
                            bookieWidth={2}
                            keys={props.keyNames}
                            operations={[commonCfg.OPERATIONS.APPLY]}
                            fetchData={(envName) => fetchData(envName)}
                            showSelectColumn={true}
                            displayActionRecord={true}
                            renderApply={(envName, refetch, records) => renderApply(envName, refetch, records)}
                        />
                        }
                        {((externalRef === "") && (isSubmitDisabled === false)) &&
                            <div style={{ marginTop: "10px", border: "solid gray 2px" }}>
                                <div style={{ display: "grid", placeItems: "center" }}>
                                    <p style={{ margin: "50px" }}>No data found</p>
                                </div>
                            </div>
                        }
                    </Panel>
                </Drawer.Body>
            </Drawer>
        </>
    );
};
export default Create;
