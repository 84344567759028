import React, { useState, useEffect } from "react";
import { Panel, Form, Drawer, MultiCascader, Button, useToaster, Message, CheckPicker, Toggle } from "rsuite";
import { uniq } from "lodash";
import { sortBy } from "lodash";
import { formCfg } from "../../../FormGroup/config";
import { playFetch } from "../../../../lib/playfetch";
import { createEnvPickerData } from "../utils";

const AppDrawer = ({...props}) => {
    const toaster = useToaster();
    const [multiApps, setMultiApps] = useState([]);
    const [multiEnvs, setMultiEnvs] = useState([]);
    const [selectEnvs, setSelectEnvs] = useState([]);
    const [selectApps, setSelectApps] = useState([]);
    const [selectAppState, setSelectAppState] = useState(false);
    const [formError, setFormError] = useState({});
    const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)

    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const appDrawerSubmitHandler = (value, event) => {
        setLoadResult(true)
        const formData = new FormData();

        /* Append flag for all apps check. Argocd API sometimes don't work for all apps.
         * Just passing false blindly here to trick ArgoCD API
         */
        formData.append(
            `${props.name.replace(/ /g, '').toLowerCase()}_all_apps`,
            false
        )

        /* Append apps */
        for (let idx = 0; idx < selectApps.length; idx++) {
            formData.append(
                `${props.name.replace(/ /g, '').toLowerCase()}_apps`,
                JSON.stringify({"name": selectApps[idx]}))
        }
        
        /* Append envs */
        for (let idx = 0; idx < selectEnvs.length; idx++) {
            if (selectEnvs[idx].includes("/")) {
                formData.append(
                    `${props.name.replace(/ /g, '').toLowerCase()}_envs`,
                    JSON.stringify({"group": selectEnvs[idx].split("/").shift(), "env": selectEnvs[idx].split("/").pop()}))
            } else {
                for (let grp_idx = 0; grp_idx < props.rawData.groups.length; grp_idx++) {
                    for (let env_idx = 0; env_idx < props.rawData.groups[grp_idx].environments.length; env_idx++) {
                        if (props.rawData.groups[grp_idx].environments[env_idx].grp_name === selectEnvs[idx]) {
                            if (props.rawData.groups[grp_idx].environments[env_idx].name !== props.env) {
                                formData.append(
                                    `${props.name.replace(/ /g, '').toLowerCase()}_envs`,
                                    JSON.stringify({"group": selectEnvs[idx], "env": props.rawData.groups[grp_idx].environments[env_idx].name}))
                            }
                        }
                    }
                }
            }
        }

        if (props.operation === "Scale Apps") {
            formData.append(
                `${props.name.replace(/ /g, '').toLowerCase()}_state`,
                selectAppState
            )
        }

        playFetch(`deployments/core/argocd/${props.name.replace(/ /g, '').toLowerCase()}`, "POST", formData)
			.then(response => {
                let msg_type = 'success'
                let msg = "Unknown"
                if (response.status >= 400) {
                    msg_type = 'error'
                    msg = response.msg
                } else {
                    msg = (<p>{response.msg}</p>);
                }
                const message = (
                        <Message showIcon type={msg_type} closable>
                            {msg}
                        </Message>
                    );
                toaster.push(message, { placement: 'topEnd', duration: 10000 })
                setLoadResult(false)
                props.onClose()
			});
    }

    const appDrawerEnvHandler = (value, event) => {
        setSelectEnvs(value)
    }

    const appDrawerAppHandler = (value, event) => {
        setSelectApps(value)
    }

    const appDrawerAppStateHandler = (value, event) => {
        setSelectAppState(value)
    }

    useEffect(() => {
        setLoadResult(false)
        setSubmitDisabled(true)
        
        const appRawData = sortBy(uniq(props.rawData.groups.map(grp =>
                                        grp.environments.map(env =>
                                            env.applications.map(app => {
            if (app.name === "core-backend") {
                return app.modules.map(module => {
                    return module.name;
                })
            } else {
                return app.name;
            }
        }).flat().flat()).flat()).flat()));

        const appData = appRawData.map(app => {
            return { label: app, value: app };
        });

        setMultiApps(appData)
        setSelectApps([])
        setMultiEnvs(createEnvPickerData(props.rawData, []))
	}, [props.rawData]);

    useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((selectEnvs.length > 0) &&
            (selectApps.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [selectEnvs, selectApps]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled));

    return (
        <>
            <Drawer
                open={props.open}
                onClose={props.onClose}
                size="lg"
                backdrop="static"
            >
                <Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        ArgoCD - {props.operation}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <Panel bordered>
                        <Form onCheck={handleFormCheck} >
                            <Form.Group controlId="appdrawer-env" >
                                <Form.ControlLabel style={{ fontWeight: 'bold' }} >Select environments:</Form.ControlLabel>
                                <Form.Control
                                    name="appdrawer-env"
                                    key="appdrawer-env"
                                    value={selectEnvs}
                                    data={multiEnvs}
                                    accepter={MultiCascader}
                                    onChange={appDrawerEnvHandler}
                                    style={{ width: formCfg.WIDTH }} />
                            </Form.Group>
                            <Form.Group controlId="appdrawer-apps" >
                                <Form.ControlLabel style={{ fontWeight: 'bold' }} >Select Apps:</Form.ControlLabel>
                                <Form.Control
                                    name="appdrawer-apps"
                                    key="appdrawer-apps"
                                    value={selectApps}
                                    data={multiApps}
                                    accepter={CheckPicker}
                                    onChange={appDrawerAppHandler}
                                    style={{ width: formCfg.WIDTH }} />
                            </Form.Group>
                            { (props.operation === "Scale Apps") && <>
                                <Form.Group controlId="appdrawer-app-state" >
                                    <Form.ControlLabel style={{ fontWeight: 'bold' }} >App State:</Form.ControlLabel>
                                    <Form.Control
                                        name="appdrawer-app-state"
                                        key="appdrawer-app-state"
                                        checked={selectAppState}
                                        accepter={Toggle}
                                        onChange={appDrawerAppStateHandler}
                                        style={{ width: formCfg.WIDTH }} />
                                </Form.Group>
                            </> }
                            <Button
                                appearance="primary"
                                disabled={isSubmitDisabled}
                                loading={loadResult}
                                onClick={appDrawerSubmitHandler}
                            >
                                    {props.operation}
                            </Button>
                        </Form>
                    </Panel>
                </Drawer.Body> 
            </Drawer>
        </>
    );
};

export default AppDrawer;
