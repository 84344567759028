import React from "react";
import { SelectPicker } from "rsuite";
import { formCfg } from "../config";

const stateOptions = [
  { value: 'Australian Capital Territory', label: 'Australian Capital Territory' },
  { value: 'New South Wales', label: 'New South Wales' },
  { value: 'Northern Territory', label: 'Northern Territory' },
  { value: 'Queensland', label: 'Queensland' },
  { value: 'South Australia', label: 'South Australia' },
  { value: 'Tasmania', label: 'Tasmania' },
  { value: 'Victoria', label: 'Victoria' },
  { value: 'Western Australia', label: 'Western Australia' }
];

const StatePicker = ({...props}) => {

    const compare = (a, b) => {
        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
    };

    const stateChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const stateCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };

    return (
        <SelectPicker
            data={stateOptions}
            onChange={stateChangeHandler}
            onClean={stateCleanHandler}
            sort={isGroup => {
                if (isGroup) {
                  return (a, b) => {
                    return compare(a.groupTitle, b.groupTitle);
                  };
                }
                return (a, b) => {
                  return compare(a.label, b.label);
                };
            }}
            style={{ width: formCfg.WIDTH }} />
    );
};

export default StatePicker;
