import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
import { playFetch } from '../../lib/playfetch';
import { appCfg, projectTypes } from "../../config/app";

export const getAccessToken = (cookie_name) => Cookies.get(cookie_name)
export const isAuthenticated = () => !!getAccessToken('access_token')
export const isAdmin = () => !!getAccessToken('admin_token')

const getCookieData = (cookie_name='access_token') => {
    const bytes = CryptoJS.AES.decrypt(
        getAccessToken(cookie_name),
        appCfg.ENCRYPT_PASS
    );
    const data = JSON.parse(CryptoJS.enc.Utf8.stringify(bytes));
    return data;
}

export const getUserId = () => {
    if (!isAuthenticated()) {
        return 'none'
    }
    const data = getCookieData()
    return data['email']
}

export const getUserName = () => {
    if (!isAuthenticated()) {
        return 'none'
    }
    const data = getCookieData()
    return `${data['given_name']} ${data['family_name']}`
}

export const getUserPicture = () => {
    if (!isAuthenticated()) {
        return 'none'
    }
    const data = getCookieData()
    return data['picture']
}

export const getUserRole = () => {
    if (!isAuthenticated()) {
        return ['none']
    }
    if (isAdmin()) {
        return ["Admin"]
    }
    const data = getCookieData()
    return data['role']
}

export const logout = async () => {
        const data = await playFetch("generic/auth/logout")
        Cookies.remove('admin_token')
        Cookies.remove('access_token')
        return data
};

export const getProjectId = () => {
    if (!getAccessToken('project_id')) {
        return projectTypes.CORE
    }
    const data = getCookieData('project_id')
    return data['project_id']
}

export const getEnvironments = () => {
    if (!isAuthenticated()) {
        return []
    }
    
    const data = getCookieData()
    return data['environments']
}