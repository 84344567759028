import React from "react";
import { FlexboxGrid, IconButton } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';

const Snapshot = ({...props}) => {
	return (
		<>
			<div style={{ marginBottom: 10, display: "flex" }} >
                <div style={{ flex: 0.5,
                              borderTop: "2px solid gray",
                              borderBottom: "2px solid gray",
                              borderLeft: "2px solid gray",
                              borderTopLeftRadius: "16px",
                              borderBottomLeftRadius: "16px",
                              backgroundColor: "#8af0c9" }}
                >
					<IconButton
						icon={<TrashIcon color="black" />}
						circle
						size="xs"
                        onClick={() => props.onDelete(props.name)}
                    />
				</div>
				<div style={{ flex: 5.5,
                              borderTop: "2px solid gray",
                              borderBottom: "2px solid gray",
                              borderRight: "2px solid gray",
                              backgroundColor: "#8af0c9" }}
                >
					<div>
						<FlexboxGrid justify="start" align="middle">
							<FlexboxGrid.Item colspan={24}>
								{props.name}
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</div>
				</div>
				<div style={{ flex: 3 }} >
					<FlexboxGrid justify="end" align="middle">
						<FlexboxGrid.Item colspan={24}>
							<div style={{ border: "2px solid gray",
										  backgroundColor: "#8af0c9",
										  borderBottomRightRadius: "16px",
										  borderTopRightRadius: "16px",
										  display: "flex",
										  alignItems: "center",
										  justifyContent: "right" }}>
                                {props.value}&nbsp;&nbsp;
							</div>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</div>
			</div>
		</>
	);
};

export default Snapshot;
