import { mobileGroups } from "./config"

export const getGroupColour = (name) => {
    switch (name) {
        case mobileGroups.DEV:
            return "#E6EFDC";
        case mobileGroups.PROD:
            return "#E4DBC4";
        case mobileGroups.LIVE:
            return "#E9C9C9";
        default:
            return "transparent";
    }
};
