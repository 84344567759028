import React, { useState, useEffect } from "react";
import { Nav } from "rsuite";
import { formCfg } from "../config";
import head from 'lodash/head';

const CustomNav = ({ data, activeKey, onSelect, ...props }) => {
    return (
        <Nav {...props} vertical activeKey={activeKey} onSelect={onSelect} style={{ width: (formCfg.WIDTH / 3) }}>
            {data.map((item, index) => <Nav.Item 
                                            key={index.toString()}
                                            eventKey={item.value}
                                            onSelect={onSelect}
                                            active={item.value === activeKey}
                                       >
                                            {item.label.slice(0, item.label.lastIndexOf('-'))}
                                       </Nav.Item>
            )}
      </Nav>
    );
};

const TabPicker = ({ ...props }) => {
    const [activeKey, setActiveKey] = useState(undefined);

    const envNameChangeHandler = (value, event) => {
        setActiveKey(value)
    };

    useEffect(() => {
        const envs = []
        envs.push(activeKey)
        if (activeKey !== undefined) {
            props.onChange(envs, props.name);
        }
        // eslint-disable-next-line
    }, [activeKey]);

    useEffect(() => {
        const firstElem = head(props.data);
        if (firstElem !== undefined) {
            setActiveKey(firstElem.value)
        }
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <>
            <CustomNav 
                appearance="tabs"
                data={props.data}
                activeKey={activeKey}
                onSelect={envNameChangeHandler} />
        </>
    );
};

export default TabPicker;
