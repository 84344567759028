import React from "react";
import { CheckPicker } from "rsuite";
import { formCfg } from "../config";

const bookieRoles = [
  { value: 'bookie_admin_betcloud', label: 'bookie_admin_betcloud' },
  { value: 'bookie_admin_global', label: 'bookie_admin_global' },
  { value: 'bookie_customer_service', label: 'bookie_customer_service' },
  { value: 'bookie_finance', label: 'bookie_finance' },
  { value: 'bookie_marketing', label: 'bookie_marketing' },
  { value: 'bookie_regulator', label: 'bookie_regulator' },
  { value: 'bookie_senior_trader', label: 'bookie_senior_trader' },
  { value: 'bookie_trader', label: 'bookie_trader' },
  { value: 'data_manager', label: 'data_manager' },
  { value: 'kyc_closure', label: 'kyc_closure' },
  { value: 'nt_ial', label: 'nt_ial' },
  { value: 'punter_profile_read_only', label: 'punter_profile_read_only' },
  { value: 'vip_trader', label: 'vip_trader' }
];

const BookieRoles = ({...props}) => {

    const compare = (a, b) => {
        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
    };

    const roleChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const roleCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };

    return (
        <CheckPicker
            data={bookieRoles}
            onChange={roleChangeHandler}
            onClean={roleCleanHandler}
            sort={isGroup => {
                if (isGroup) {
                  return (a, b) => {
                    return compare(a.groupTitle, b.groupTitle);
                  };
                }
                return (a, b) => {
                  return compare(a.label, b.label);
                };
            }}
            style={{ width: formCfg.WIDTH }} />
    );
};

export default BookieRoles;
