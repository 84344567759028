import React from "react";
import { FlexboxGrid } from 'rsuite';
import Sha from "./sha";
import Argocd from "./Argocd";

const Snapshot = ({...props}) => {
	return (
		<>
			<div style={{ marginBottom: props.marginBottom, display: "flex" }} >
				<div style={{ flex: 5,
                              border: "2px solid gray",
                              borderBottomLeftRadius: "16px",
                              borderTopLeftRadius: "16px",
                              backgroundColor: "#8af0c9" }} >
                    <div style={{ display: "flex" }} >
                        <Argocd name={props.name} value={props.app} env = {props.env} />
					    <div style={{ marginLeft: "10px" }} >
						    <FlexboxGrid justify="start" align="middle">
							    <FlexboxGrid.Item colspan={24}>
								    <b>{props.name}</b>
							    </FlexboxGrid.Item>
						    </FlexboxGrid>
					    </div>
                    </div>
				</div>
				<div style={{ flex: 3 }} >
					<FlexboxGrid justify="end" align="middle">
						<FlexboxGrid.Item colspan={24}>
							<div style={{ border: "2px solid gray",
										  backgroundColor: "#8af0c9",
										  borderBottomRightRadius: "16px",
										  borderTopRightRadius: "16px",
										  display: "flex",
										  alignItems: "center",
										  justifyContent: "right" }}>
								<Sha
									group={props.group}
									env={props.env}
									name={props.name}
									value={props.app}
									readOnly={props.readOnly} />
							</div>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</div>
			</div>
		</>
	);
};

export default Snapshot;
