import React, { useEffect } from "react";
import { Form, Schema, Input, InputPicker, InputNumber, Toggle } from "rsuite";
import { addYears } from 'date-fns';
import EnvPicker from "./EnvPicker";
import StatePicker from "./StatePicker";
import DateSelect from "./DateSelect";
import DateRange from "./DateRange";
import Timestamp from "./Timestamp";
import BookieRoles from "./BookieRoles";
import UploadFile from "./UploadFile";
import ReadFile from "./ReadFile";
import AccessTypePicker from "./AccessTypePicker";
import FeedStep from "./FeedStep";
import ExternalRef from "./ExternalRef";
import { formCfg, formGroupType } from "./config";
import WithdrawalStatusPicker from "./WithdrawalStatusPicker";
import QueryPicker from "./QueryPicker";

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

const FormGroup = ({...props}) => {

    const changeHandler = (value, name) => {
        props.onChange(value, name);
    };

    useEffect(() => {
        const newValue = extractType(props.type, true)
        if ((extractType(props.type, false) === formGroupType.READONLY) &&
            (newValue !== props.value)) {
            props.onChange(newValue, props.name)
        }
    }, [props]);

    const extractType = (value, extractSubType) => {
        const types = [
            formGroupType.FEEDSTEP,
            formGroupType.QUERYPICKER,
            formGroupType.READFILE,
            formGroupType.READONLY,
            formGroupType.UPLOADFILE
        ]

        for(let idx = 0; idx < types.length; ++idx) {
            if(value.startsWith(types[idx] + "/")) {
                if(extractSubType === true) {
                    return value.substr(types[idx].length + 1)
                } else {
                    return types[idx];
                }
            }
        }
        return value;
    };

    const renderFormElement = () => {
        let type = extractType(props.type, false)
        let rule = null
        switch(type) {
            case formGroupType.ACCESSTYPEPICKER:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                            name={props.name}
                            value={props.value}
                            accepter={AccessTypePicker}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler} />
            case formGroupType.WITHDRAWALSTATUSPICKER:
                if (props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                    name={props.name}
                    value={props.value}
                    accepter={WithdrawalStatusPicker}
                    rule={rule}
                    errorPlacement='rightEnd'
                    onChange={changeHandler} />
            case formGroupType.ENVPICKER:
                if(props.required === true) {
                    if(props.multiPick === true) {
                        rule = Schema.Types.ArrayType().isRequired('This field is required.')
                    } else {
                        rule = Schema.Types.StringType().isRequired('This field is required.')
                    }
                }

                return <Form.Control
                            name={props.name}
                            value={props.value}
                            type={props.envType}
                    exclude={props.exclude}
                            accepter={EnvPicker}
                            multiPick={props.multiPick}
                            cascade={true}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler}
                            onClean={changeHandler} />
            case formGroupType.STATEPICKER:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                            name={props.name}
                            value={props.value}
                            accepter={StatePicker}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler} />
            case formGroupType.NUMBER:
                if(props.required === true) {
                    rule = Schema.Types.NumberType().isRequired('This field is required.')
                }

                return <Form.Control
                            onChange={(value, event) => changeHandler(value, event.target.name)}
                            name={props.name}
                            value={props.value}
                            accepter={InputNumber}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.TEXT:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                            onChange={(value, event) => changeHandler(value, event.target.name)}
                            name={props.name}
                            value={props.value}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.TEXTAREA:
                if (props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                    onChange={(value, event) => changeHandler(value, event.target.name)}
                    name={props.name}
                    value={props.value}
                    accepter={Textarea}
                    rule={rule}
                    errorPlacement='rightEnd'
                    style={{ width: formCfg.WIDTH }} />
            case formGroupType.READONLY:
                return <Form.Control
                            name={props.name}
                            value={props.value}
                            readOnly
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.MOBILE:
                if(props.required === true) {
                    rule = Schema.Types.StringType()
                                            .isRequired('This field is required.')
                                            .pattern(/^\d{10}$/,'Please enter a valid mobile number like 0412345678')
                } else {
                    rule = Schema.Types.StringType()
                                            .pattern(/^\d{10}$/,'Please enter a valid mobile number like 0412345678')
                }

                return <Form.Control
                            onChange={(value, event) => changeHandler(value, event.target.name)}
                            name={props.name}
                            value={props.value}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.EMAIL:
                if(props.required === true) {
                    rule = Schema.Types.StringType()
                                            .isRequired('This field is required.')
                                            .isEmail('Please enter a valid email address.')
                } else {
                    rule = Schema.Types.StringType()
                                            .isEmail('Please enter a valid email address.')
                }

                return <Form.Control
                            onChange={(value, event) => changeHandler(value, event.target.name)}
                            name={props.name}
                            value={props.value}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.BIRTHDATE:
                if (props.required === true) {
                    rule = Schema.Types.DateType()
                        .isRequired('This field is required.')
                        .max(addYears(new Date(), -18),
                            'Please enter a valid date of birth. Must be atleast 18 years of age.')
                } else {
                    rule = Schema.Types.DateType()
                        .max(addYears(new Date(), -18),
                            'Please enter a valid date of birth. Must be atleast 18 years of age.')
                }

                return <Form.Control
                    name={props.name}
                    value={props.value}
                    accepter={DateSelect}
                    rule={rule}
                    errorPlacement='rightEnd'
                    onChange={changeHandler} />
            case formGroupType.TIMESTAMP:
                if (props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }
                return <Form.Control
                    name={props.name}
                    value={props.value}
                    accepter={DateSelect}
                    rule={rule}
                    errorPlacement='rightEnd'
                    onChange={changeHandler} />
            case formGroupType.DATERANGE:
                if(props.required === true) {
                    rule = Schema.Types.ArrayType().isRequired('This field is required.')
                }
                
                return <Form.Control
                            name={props.name}
                            value={props.value}
                            accepter={DateRange}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler} />
            case formGroupType.EXPIRYTIMESTAMP:
                if(props.required === true) {
                    rule = Schema.Types.DateType()
                                            .isRequired('This field is required.')
                }

                return <Form.Control
                            name={props.name}
                            value={props.value}
                            minDate={new Date()}
                            accepter={Timestamp}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler} />
            case formGroupType.PREVIOUSTIMESTAMP:
                if(props.required === true) {
                    rule = Schema.Types.DateType()
                                            .isRequired('This field is required.')
                }

                return <Form.Control
                            name={props.name}
                            value={props.value}
                            maxDate={new Date()}
                            accepter={Timestamp}
                            rule={rule}
                            errorPlacement='rightEnd'
                            onChange={changeHandler} />
            case formGroupType.DATAPICKER:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                            data={props.data}
                            name={props.name}
                            value={props.value}
                            onChange={changeHandler}
                            accepter={InputPicker}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }} />
            case formGroupType.BOOLEAN:
                return <Toggle 
                            size="lg"
                            checkedChildren="True"
                            unCheckedChildren="False"
                            checked={(props.value === "") ? false : props.value}
                            name={props.name}
                            onChange={(checked, event) => changeHandler(checked, event.target.name)} />
            case formGroupType.UPLOADFILE:
                if(props.required === true) {
                    rule = Schema.Types.ObjectType().shape({
                                                        name: Schema.Types.StringType()
                                                                            .isRequired('This field is required.')})
                }

                return <Form.Control
                            type={extractType(props.type, true)}
                            name={props.name}
                            onChange={changeHandler}
                            accepter={UploadFile}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                       />
            case formGroupType.FEEDSTEP:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }

                return <Form.Control
                            type={extractType(props.type, true)}
                            name={props.name}
                            value={props.value}
                            playbookId={props.playbookId}
                            onChange={changeHandler}
                            accepter={FeedStep}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                        />
            case formGroupType.EXTERNALREF:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }
            
                return <Form.Control
                            name={props.name}
                            value={props.value}
                            playbookId={props.playbookId}
                            onChange={changeHandler}
                            accepter={ExternalRef}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                        />
            case formGroupType.QUERYPICKER:
                if(props.required === true) {
                    rule = Schema.Types.StringType().isRequired('This field is required.')
                }
            
                return <Form.Control
                            queryInput={extractType(props.type, true)}
                            name={props.name}
                            multiPick={false}
                            disabled={false}
                            plaintext={false}
                            value={props.value}
                            onChange={changeHandler}
                            accepter={QueryPicker}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                        />
            case formGroupType.READFILE:
                if(props.required === true) {
                    rule = Schema.Types.ObjectType().shape({
                                                        name: Schema.Types.StringType()
                                                                            .isRequired('This field is required.')})
                }

                return <Form.Control
                            type={extractType(props.type, true)}
                            name={props.name}
                            onChange={changeHandler}
                            accepter={ReadFile}
                            rule={rule}
                            errorPlacement='rightEnd'
                            style={{ width: formCfg.WIDTH }}
                       />
            case formGroupType.BOOKIEROLES:
                return <BookieRoles
                            name={props.name}
                            value={props.value}
                            onChange={changeHandler} />
            default:
                return <React.Fragment />
        }
    }

    return (
        <Form.Group controlId={props.name} >
            <Form.ControlLabel style={{ fontWeight: 'bold' }} >{props.label}:</Form.ControlLabel>
            {renderFormElement()}
        </Form.Group>
    );
};

export default FormGroup;