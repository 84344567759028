import React from "react";
import Snapshot from "./snapshot";

const Display = ({...props}) => {
    return (
        <>
            {Object.keys(props.data).map((item, index) =>
                <Snapshot
                    name={item}
                    value={props.data[item]}
                    onChange={(name, value) => props.onChange(name, value)}
                    onDelete={(name) => props.onDelete(name)}
                    key={`devicecfg-${index}`}
                />
            )}
        </>
    );
};

export default Display;
