import React from "react";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { uniq } from "lodash";
import Display from "../Common/display";
import { offeringsCfg } from "./config";
import { fetch } from "../Common/lib/fetch";
import concat from 'lodash/concat';
import { envType } from "../../FormGroup/config";
import { playFetch } from "../../../lib/playfetch";
import Update from "./update";
import { environments } from "../../../config/environments";

const GlobalOfferings = ({...props}) => {
    const [excludedEnvs, setExcludedEnvs] = React.useState([]);

    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch/${env}`}
        })

        const data = await fetch(offeringsCfg.KEYS.map(key => key.name), requestData);

        const goData = data.map(item => {
                            const childEnvs = uniq(item.children.map(x => x.name))
                            if(childEnvs.length === envName.length) {
                                return item;
                            } else {
                                return undefined;
                            }
                        }).filter(Boolean);

        return goData;
    }

    const renderUpdate = (envName, refetch, records) => {
        const childEnvs = new Set(records.children.map(item => {
                                    return item.name.substring(0, item.name.lastIndexOf("-"))
                                }))
        const allCoreEnvs = environments.core.map(item => {
            return item.label
        })
        const excEnvs = uniq(concat(allCoreEnvs, excludedEnvs).filter(item => !childEnvs.has(item)));

        return <Update {...props} excludedEnvs={excEnvs} records={records} refetchData={refetch} />
    }

    React.useEffect(() => {
        const envs = []
        playFetch("core/status/fetch/scaledown")
            .then(response => {
                setExcludedEnvs(concat(response, envs));
            });

    }, [props.id])

    return (
        <>
        {(excludedEnvs.length === 0) && <div style={{ display: "grid", placeItems: "center" }}>
                <SpinnerIcon pulse style={{ fontSize: '5em', margin: "50px" }} />
        </div>}
        {(excludedEnvs.length !== 0) && <Display
            {...props}
            displayEnv={false}
            selectEnv={false}
            envTypes={[envType.CORE, envType.WINCORE]}
            excludedEnvs={excludedEnvs}
            colWidth={offeringsCfg.KEYS.map(key => key.width)}
            actionWidth={offeringsCfg.ACTIONWIDTH}
            keys={offeringsCfg.KEYS.map(key => key.name)}
            operations={offeringsCfg.OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={true}
            renderUpdate={(envName, refetch, records) => renderUpdate(envName, refetch, records)}
        />}
        </>
    );
};
export default GlobalOfferings;