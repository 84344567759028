import React from "react";
import { Panel, FlexboxGrid, ButtonToolbar, IconButton, ButtonGroup, Divider, useToaster, Message, Loader } from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import isEqual from "lodash/isEqual";
import { playFetch } from "../../../../../lib/playfetch";
import Display from "./display";
import AddKey from "./addkey";

const FeatureFlag = ({...props}) => {
    const toaster = useToaster();
    const [loading, setLoading] = React.useState(true);
    const [oldCfgData, setOldCfgData] = React.useState({});
    const [newCfgData, setNewCfgData] = React.useState({});
    const [showAddForm, setShowAddForm] = React.useState(false);

    const fetchData = () => {
		setLoading(true)
		playFetch(`deployments/core/envcfg/fetch/${props.group}/${props.env}`)
				.then(data => {
					setOldCfgData(data);
					setLoading(false);
				});
	};

    const postData = () => {
        const formData = new FormData();
        formData.append("cfg_data", JSON.stringify(newCfgData));
		playFetch(`deployments/core/envcfg/set/${props.group}/${props.env}`, "POST", formData)
				.then(response => {
                        response.status.forEach(element => {
                            let msg_type = 'success'
                            let msg = "Unknown"
                            if (element.status >= 400) {
                                msg_type = 'error'
                                msg = element.reason
                            } else {
                                msg = (<>
                                        <p>
                                            {element.id_type}:&nbsp;
                                            <a href={element.href} rel="noreferrer" target="_blank">{element.id}</a>
                                        </p>
                                    </>);
                            }
                            const message = (
                                <Message showIcon type={msg_type} closable>
                                    {msg}
                                </Message>
                            );
                            toaster.push(message, { placement: 'topEnd', duration: 10000 })
                        });
                        setLoading(false)
                        props.onClose()
                    });
	};

	React.useEffect(() => {
		fetchData();
        // eslint-disable-next-line
	}, []);

    React.useEffect(() => {
		setNewCfgData(oldCfgData);
	}, [oldCfgData]);

    const onKeyAdd = (name, value) => {
        let newData = Object.assign({}, newCfgData);
        newData[name] = value;
        setNewCfgData(newData);
        setShowAddForm(false);
    };

    const onKeyUpdate = (name, value) => {
        let newData = Object.assign({}, newCfgData);
        newData[name] = value;
        setNewCfgData(newData);
    };

    const onKeyDelete = (name) => {
        let newData = Object.assign({}, newCfgData);
        delete newData[name];
        setNewCfgData(newData);
    };

    const cancelHandler = (event) => {
        event.preventDefault();
        let newData = Object.assign({}, oldCfgData);
        setNewCfgData(newData);
    };

    const postHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        setOldCfgData(newCfgData)
        postData();
    };

    return (
        <>
            {(showAddForm) && <AddKey onClose={() => setShowAddForm(false)} onAdd={onKeyAdd} />}
            {(!showAddForm) && <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={24}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right"
                    }}>
                        <ButtonToolbar>
                            <IconButton
                                icon={<PlusIcon color="#4C4E52" />}
                                onClick={() => setShowAddForm(!showAddForm)}
                                circle
                                size="md"
                                color="cyan"
                                appearance="primary"
                            />
                        </ButtonToolbar>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>}
            {(!showAddForm) && <Panel shaded style={{ backgroundColor: "white", marginTop: 20 }}>
                {(isEqual(oldCfgData, newCfgData) === false) && <><FlexboxGrid justify="end">
                    <FlexboxGrid.Item colspan={3}>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <IconButton
                                    icon={<CheckIcon color="green" />}
                                    size="sm"
                                    onClick={postHandler}
                                    style={{
                                        border: "1px solid gray",
                                        borderTopLeftRadius: "20px",
                                        borderBottomLeftRadius: "20px"
                                    }}
                                />
                                <IconButton
                                    icon={<CloseIcon color="red" />}
                                    size="sm"
                                    onClick={cancelHandler}
                                    style={{
                                        border: "1px solid gray",
                                        borderTopRightRadius: "20px",
                                        borderBottomRightRadius: "20px"
                                    }}
                                />
                            </ButtonGroup>
                        </ButtonToolbar>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                    <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
                </>}
                <Display
                    data={newCfgData}
                    onChange={onKeyUpdate}
                    onDelete={onKeyDelete}
                />
            </Panel>}
            {(loading) && <Loader backdrop vertical size="lg" />}
        </>
    );
};

export default FeatureFlag;
