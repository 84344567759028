import React, { useEffect, useState } from "react";
import { Container, Header, Content, Footer, Stack, Divider, ButtonToolbar, Button, Sidebar } from 'rsuite';
import DisplayTable from "./displaytable";
import EnvPicker from "../../FormGroup/EnvPicker";
import { environments } from "../../../config/environments";
import { getUserRole } from "../../Authentication";
import { envType } from "../../FormGroup/config";
import { commonCfg } from "./config";
import { formCfg } from "../../FormGroup/config";

const Display = ({...props}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [envName, setEnvName] = useState([]);
    const [addNewRecord, setAddNewRecord] = useState(false);

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const fetchEnvNames = () => {
        let envData = ["default"]
        if (props.envTypes !== undefined) {
            envData = props.envTypes.map(eType => {
                let envMap = environments.core;
                let suffix = '-' + envType.CORE
                if (eType === envType.SREPLAYBOOK) {
                    envMap = environments.sreplaybook;
                    suffix = ""
                } else if (eType === envType.WINCORE) {
                    envMap = environments.wincore;
                }
            
                const data = envMap.map((item) => {
                    if (getUserRole().some(role => item.roles.includes(role))) {
                        if((props.excludedEnvs === undefined) || (props.excludedEnvs.includes(item.label)) === false) {
                            return item.label.toLowerCase() + suffix
                        }
                    }
                    return undefined
                }).filter(Boolean);
                return data;
            }).filter(Boolean);
            envData = envData.flat();
        }
        setEnvName(envData);
    }

    const fetchData = () => {
        props.fetchData(envName)
            .then(response => {
                setData(response);
                setLoading(false);
            });
    }

    const refetchData = () => {
        setLoading(true)
        setData([])
        setAddNewRecord(false)
        fetchData()
    };

    useEffect(() => {
        setData([])
        setAddNewRecord(false)
        if ((props.selectEnv === undefined) || (props.selectEnv === false)) {
            fetchEnvNames();
        }
        // eslint-disable-next-line
    }, [props.id, props.keys]);

    useEffect(() => {
        if(envName.length === 0) {
            setData([])
        } else {
            setLoading(true)
            fetchData();
        }
        // eslint-disable-next-line
    }, [envName]);

    return (
        <div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Container>
                <Header>
                    <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Stack spacing={1}>
                            {((props.selectEnv === true) && (props.showEnvPickerAsTab === false)) && <div>
                                <EnvPicker
                                    name="envName"
                                    value={envName}
                                    multiPick={false}
                                    showAsTab={props.showEnvPickerAsTab}
                                    exclude={props.excludedEnvs}
                                    type={props.envTypes.map(item => { return item + "/ALL" })}
                                    label="Environment"
                                    onChange={envNameChangeHandler}
                                />
                            </div>}
                        </Stack>
                        <Stack spacing={6}>
                            <ButtonToolbar>
                                {((props.operations.includes(commonCfg.OPERATIONS.CREATE)) &&
                                    (((envName.length !== 0) || (props.addGlobally === true)))) &&
                                <Button
								    color="cyan"
								    size="md"
								    appearance="primary"
								    disabled={loading || addNewRecord}
                                    onClick={() => { setAddNewRecord(true) }}
								    style={{ borderRadius: "20px" }}>
									    <div style={{ color: "#4C4E52" }} >
										    Add new record
									    </div>
							    </Button>}
                            </ButtonToolbar>
                        </Stack>
                    </Stack>
                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Stack spacing={6}>
                            <></>
                        </Stack>
                        <Stack spacing={6}>
                            <></>
                        </Stack>
                    </Stack>
                </Header>
                {<Content style={{ marginTop: "10px", border: "solid gray 2px" }}>
                    <Container style={{ marginTop: "10px" }}>
                            {((props.selectEnv === true) && (props.showEnvPickerAsTab === true)) && <Sidebar width={formCfg.WIDTH / 3}>
                                <EnvPicker
                                    name="envName"
                                    value={envName}
                                    multiPick={false}
                                    showAsTab={props.showEnvPickerAsTab}
                                    exclude={props.excludedEnvs}
                                    type={props.envTypes.map(item => { return item + "/ALL" })}
                                    label="Environment"
                                    onChange={envNameChangeHandler}
                                />
                            </Sidebar>}
                            <Content>
                            <DisplayTable
                                {...props}
                                loading={loading}
                                data={data}
                                dataLength={(data === undefined) ? 0 : data.length}
                                totalEnvs={envName.length}
                                envName={(props.selectEnv === true) ? envName : []}
                                addNewRecord={addNewRecord}
                                refetchData={() => refetchData()}
                            />
                            </Content>
                    </Container>
                </Content>}
                <Footer>
                    <></>
                </Footer>
            </Container>
        </div>
    );
};
export default Display;
