import React, { useState, useEffect } from "react";
import { Drawer, Panel, Input, Button, Form, useToaster, Message } from 'rsuite';
import EnvPicker from "../../FormGroup/EnvPicker";
import { formCfg } from "../../FormGroup/config";
import { playFetch } from "../../../lib/playfetch";
import { isProdEnv } from "../../../lib/gitops";
import environments from "../../../config/environments";

const Create = ({...props}) => {
	const toaster = useToaster();
	const [envName, setEnvName] = useState([]);
	const [branchName, setBranchName] = useState("master");
	const [formError, setFormError] = useState({});
	const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)

	const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

	const branchNameChangeHandler = (value, event) => {
        setBranchName(value);
    };

	const handleFormCheck = (formError) => {
        setFormError(formError);
    };
	  
	const createSubmitHandler = (value, event) => {
        setLoadResult(true)

        const formData = new FormData();

		envName.forEach(env => {
			let envText = ""

			if (env.endsWith("-betcloud-core") === true) {
				if (isProdEnv(`${env.split("-")[0]}-betcloud`,environments.environments.core) === true) {
					envText += "production/"
				}
				envText += env.split("-")[0]
			} else {
				if (isProdEnv(env,environments.environments.wincore) === true) {
					envText += "production/"
				}
				envText += env
			}
			formData.append("env_names", envText)
		});
		formData.append("branch_name", branchName)
		
		playFetch("deployments/terraform/create/plan", "POST", formData)
            .then(data => {
				data.forEach(element => {
                    let msg_type = 'success'
                    let msg = "Unknown"
                    if (element.ok === false) {
                        msg_type = 'error'
                        msg = element.reason
                    } else {
                        msg = (<>
                                <p>
                                    {element.id_type}:&nbsp;
                                    <a href={element.href} rel="noreferrer" target="_blank">{element.id}</a>
                                </p>
                            </>);
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            {msg}
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 10000 })
                });
                setLoadResult(false)
				props.onClose()

            });
	}

	useEffect(() => {
        setLoadResult(false)
        setSubmitDisabled(true)
		setEnvName([])
		setBranchName("master")
	}, []);

	useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((envName.length > 0) &&
            (branchName.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [envName, branchName]);

	const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled));
	
	return (
		<>
			<Drawer
                open={props.open}
                onClose={props.onClose}
                size="lg"
                backdrop="static"
            >
				<Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        Create a plan
                    </Drawer.Title>
                </Drawer.Header>
				<Drawer.Body>
					<Panel bordered>
                        <Form onCheck={handleFormCheck} >
                            <Form.Group controlId="create-env" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Select environments:</Form.ControlLabel>
                                <Form.Control
									name="create-env"
									key="create-env"
									value={envName}
									type={['core/ALL', 'wincore/ALL']}
									multiPick={true}
									accepter={EnvPicker}
									onChange={envNameChangeHandler}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
							<Form.Group controlId="create-branch" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify branch:</Form.ControlLabel>
                                <Form.Control
									name="create-branch"
									key="create-branch"
									value={branchName}
									accepter={Input}
									onChange={branchNameChangeHandler}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
							<Button
                                appearance="primary"
                                disabled={isSubmitDisabled}
                                loading={loadResult}
                                onClick={createSubmitHandler}
                            >
                                    Create a plan
                            </Button>
						</Form>
					</Panel>
				</Drawer.Body>
			</Drawer>
		</>
	);
};

export default Create;
