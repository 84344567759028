import React from "react";
import { recordsCfg } from "../config.js";
import QuickLinks from "./QuickLinks";
import FeaturedRace from "./FeaturedRace";
import FeaturedMatch from "./FeaturedMatch";
import { playFetch } from "../../../lib/playfetch.js";

const QuickNav = ({...props}) => {
    const [excludedEnvs, setExcludedEnvs] = React.useState(undefined);

    React.useEffect(() => {
        playFetch("core/status/fetch/scaledown")
            .then(response => {
                setExcludedEnvs(response);
            });

    }, [props.id])

    return (
        <>
            {(excludedEnvs !== undefined) && <>
                {(props.id === recordsCfg.TYPE.QUICKLINKS) && <QuickLinks {...props} excludedEnvs={excludedEnvs} />}
                {(props.id === recordsCfg.TYPE.FEATUREDRACE) && <FeaturedRace {...props} excludedEnvs={excludedEnvs} />}
                {(props.id === recordsCfg.TYPE.FEATUREDMATCH) && <FeaturedMatch {...props} excludedEnvs={excludedEnvs} />}
            </>}
        </>
    );
};
export default QuickNav;