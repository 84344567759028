import React from "react";
import { Nav, Divider, Whisper, Popover } from "rsuite";
import MenuIcon from '@rsuite/icons/Menu';
import { Link } from 'react-router-dom';
import uniq from "lodash/uniq"
import { environments } from "../../config/environments";
import { appCfg } from "../../config/app";
import { getUserRole } from "../Authentication";
import UserDropdown from "../UserDropdown";

const envs = environments.core.map((item, index) => ({ label: item.label, value: item.url, type: item.type }));
const envTypes = uniq(envs.map((item) => { return item.type }))

const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
	<Link ref={ref} to={href} {...rest}>
		{children}
	</Link>
));

export default function Navbar({...props}) {
    const userDropDownRef = React.useRef();

    const speaker = (
        <Popover arrow={false}>
            <UserDropdown
                useDarkTheme={props.useDarkTheme}
                changeTheme={props.changeTheme}
                onClose={() => userDropDownRef.current.close()}
            />
        </Popover>
    );
    
	return (
		<>
			<div style={{ position: "relative", top: "0", display: "flex", justifyContent: "center" }}>
				<Nav>
					<Nav.Item as={NavLink} href="/">Home</Nav.Item>
					{(getUserRole().some(role => appCfg.DATABASE_ALLOWED_ROLES.includes(role)) === true) ?
						<React.Fragment>
                            <Nav.Item as={NavLink} href="/database">Database</Nav.Item>
                            {(getUserRole().some(role => ["DB-RW", "DB-Approvers"].includes(role)) === true) && <Nav.Menu title="Jobs" key="jobs">
                                <Nav.Menu title="Run Type" key="run_type">
                                    <Nav.Menu title="Manual" key="manual_jobs">
                                        <Nav.Item as={NavLink} href="/jobs/manual/pending">Pending</Nav.Item>
                                        <Nav.Item as={NavLink} href="/jobs/manual/running">Running</Nav.Item>
                                        <Nav.Item as={NavLink} href="/jobs/manual/completed">Completed</Nav.Item>
                                    </Nav.Menu>
                                    <Nav.Item as={NavLink} href="/jobs/scheduled">Scheduled</Nav.Item>
                                </Nav.Menu>
                                {(getUserRole().some(role => (role === "DB-Approvers")) === true) && <Nav.Item as={NavLink} href="/jobs/approval">Approval</Nav.Item>}
                            </Nav.Menu>}
                        </React.Fragment> :
						<React.Fragment />
					}
					{(getUserRole().some(role => appCfg.GITOPS_ALLOWED_ROLES.includes(role)) === true) ?
						<Nav.Menu title="Deployment">
							<Nav.Item as={NavLink} href="/deployment/core">Core</Nav.Item>
							<Nav.Item as={NavLink} href="/deployment/mobile">Mobile</Nav.Item>
                            <Nav.Item as={NavLink} href="/deployment/terraform">Terraform</Nav.Item>
						</Nav.Menu> :
						<React.Fragment />
					}
					<Nav.Menu title="Clients">
					{envTypes.map((envType, typeIndex) =>
                                        <Nav.Menu title={envType} key={typeIndex}>
                                            {envs.map((item, index) =>
                                                (item.type === envType) && <Nav.Menu title={item.label} key={index}>
													<Nav.Item
														as={NavLink}
														eventKey={index}
														href={"https://" + item.value}
														target="_blank">
															Web
													</Nav.Item>
													<Nav.Item
														as={NavLink}
														eventKey={index}
														href={"https://portal." + item.value}
														target="_blank">
															Portal
													</Nav.Item>
											   </Nav.Menu>)}
                                        </Nav.Menu>)}
					</Nav.Menu>
				</Nav>
				<Divider style={{ marginTop: 2, marginBottom: 5 }} />
				<div style={{ display: "inline-block", position: "absolute", right: "20px", top: "-45px" }} >
                    <Whisper
                        placement="bottomEnd"
                        trigger="click"
                        controlId="user-profile-click"
                        speaker={speaker}
                        ref={userDropDownRef}
                    >
                        <MenuIcon />
                    </Whisper>
				</div>
			</div>
		</>
	);
}
