import React, { useState } from "react";
import { MultiCascader } from "rsuite";
import find from 'lodash/find';
import { formCfg } from "../config";

const CascadePicker = ({ ...props }) => {
    const [selectEnvs, setSelectEnvs] = useState([]);

    const envNameChangeHandler = (value, event) => {
        const nextValue = value.map(item => {
                            if(item.includes("/")) {
                                return item;
                            } else {
                                const foundItem = find(props.data, {value: item});
                                const childValue = foundItem["children"].map(child => {
                                    return `${item}/${child["value"]}`
                                })
                                return childValue;
                            }
                        }).flat();
        
        let newValue = [];
        if (props.rawValue === true) {
            newValue = nextValue;
        } else {
            newValue = nextValue.map(item => {
                return `${item.split("/").pop()}`
            });
        }

        setSelectEnvs(value)
        props.onChange(newValue, props.name);
    };

    const envNameCleanHandler = (event) => {
        setSelectEnvs([])
        props.onClean([], props.name);
    };

    return (
        <>
            <MultiCascader
                name={props.name}
                data={props.data}
                value={selectEnvs}
                onChange={envNameChangeHandler}
                onClean={envNameCleanHandler}
                menuWidth={(formCfg.WIDTH / 2)}
                style={{ width: (props.width === undefined) ? formCfg.WIDTH : props.width}}
            />
        </>
    );
};

export default CascadePicker;
