import React, { useState } from "react";
import { Stack, FlexboxGrid, Toggle, Input, Checkbox } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import split from 'lodash/split';
import has from 'lodash/has';
import isBoolean from 'lodash/isBoolean';
import { commonCfg } from "./config";
import "./records.css"

const DisplayTable = ({...props}) => {
    const [operation, setOperation] = useState("");
    const [actionRecordId, setActionRecordId] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);

    const handleActionClick = (operation, record, isHeader) => {
        if (isHeader === false) {
            let nextRecords = []
            nextRecords.push(record.id)
            setActionRecordId(nextRecords);
            setOperation(operation);
        } else {
            setActionRecordId(selectedRecords)
            setOperation(operation)
        }
    }

    const renderChildren = offset => {
        let envs = props.data[offset]["children"]
        if((props.totalEnvs === props.data[offset]["children"].length) &&
            (props.totalEnvs !== 1)) {
            envs = [{name: "All"}]
        }
        const elem = envs.map((item, index) =>
                            <div
                                key={`child-div-${index}`}
                                style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    border: "1px grey solid",
                                    borderRadius: "10px",
                                    margin: "-1px"
                                }}
                            >
                                {(item.name.endsWith("-betcloud-core")) ?
                                    split(item.name, "-")[0] :
                                    item.name.substr(0, item.name.lastIndexOf("-"))}
                            </div>
                    )
        return elem;
    }

    const renderList = data => {
        const elem = data.map((item, index) =>
                            <div
                                key={`list-div-${index}`}
                                style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    border: "1px grey solid",
                                    borderRadius: "10px",
                                    margin: "-1px"
                                }}
                            >
                                {item}
                            </div>
                    )
        return (
            <Stack wrap spacing={6} justifyContent="center">
                {elem}
            </Stack>
        );
    }

    const selectAllRecordHandler = (value, checked, event) => {
        if (checked === true) {
            const idList = props.data.map((item) => { return item.id });
            setSelectedRecords(idList)
        } else {
            setSelectedRecords([])
        }
    }

    const selectRecordHandler = (value, checked, event) => {
        let newList = [...selectedRecords]
        if (checked === true) {
            newList.push(value)
        } else {
            newList = selectedRecords.filter(item => item !== value)
        }
        setSelectedRecords(newList)
    }

    const renderActionCell = (row, isHeader) => {
        return (
            <Stack spacing={20} justifyContent="center">
                {((isHeader === false) && (props.operations.includes(commonCfg.OPERATIONS.UPDATE))) && <div
                    onClick={() => handleActionClick(commonCfg.OPERATIONS.UPDATE, row, isHeader)}
                    style={{
                        flex: 1,
						cursor: "pointer",
                        "&:hover": {
                            cursor: "pointer"
                        }
				    }}
                >
                    <EditIcon color='black' style={{ fontSize: '1.5em' }} />
                </div>}
                {(props.operations.includes(commonCfg.OPERATIONS.DELETE)) && <div
                    onClick={() => handleActionClick(commonCfg.OPERATIONS.DELETE, row, isHeader)}
                    style={{
                        flex: 1,
						cursor: "pointer",
                        "&:hover": {
                            cursor: "pointer"
                        }
					}}
                >
                    <TrashIcon color='black' style={{ fontSize: '1.5em' }} />
                </div>}
                {(props.operations.includes(commonCfg.OPERATIONS.APPLY)) && <div
                    onClick={() => handleActionClick(commonCfg.OPERATIONS.APPLY, row, isHeader)}
                    style={{
                        flex: 1,
						cursor: "pointer",
                        "&:hover": {
                            cursor: "pointer"
                        }
					}}
                >
                    <FileUploadIcon color='black' style={{ fontSize: '1.5em' }} />
                </div>}
                {(props.operations.includes(commonCfg.OPERATIONS.CUSTOM)) && <div>
                    {props.renderCustomAction(row, props.refetchData)}
                </div>}
            </Stack>
        );
    }

    const renderTable = () => {
        if (props.loading === true) {
            return (
                <div style={{ display: "grid", placeItems: "center" }}>
                    <SpinnerIcon pulse style={{ fontSize: '5em', margin: "50px" }} />
                </div>
            );
        } else if ((props.dataLength === 0) && (props.addNewRecord === false)) {
            return (
                <div style={{ display: "grid", placeItems: "center" }}>
                    <p style={{ margin: "50px" }}>No data found</p>
                </div>
            );
        } else if ((props.addGlobally === true) && (props.addNewRecord === true)) {
            return (
                props.renderCreate(props.envName, props.refetchData)
            );
        } else {
            return (
                <>
                <FlexboxGrid justify="space-between" className="records-header-row" style={{ display: "flex", margin: "5px", height: "auto" }}>
                    {(props.showSelectColumn === true) && <FlexboxGrid.Item colspan={1} key={`header-select-column`}>
                        <Checkbox
                            checked={selectedRecords.length === props.dataLength}
                            indeterminate={selectedRecords.length > 0 &&
                                            selectedRecords.length < props.dataLength}
                            onChange={selectAllRecordHandler}
                            style={{
                                "--rs-checkbox-border": "black"
                            }}
                        />
                    </FlexboxGrid.Item>}
                    {(props.displayEnv === true) && <FlexboxGrid.Item colspan={props.bookieWidth} key={`header-bookmaker`}>
                        <div className="records-header-col">
                            BOOKMAKER/S
                        </div>
                    </FlexboxGrid.Item>}
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={props.colWidth[index]} key={`header-${index}`}>
                            <div className="records-header-col">
                                {key}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`header-action`}>
                        <div className="records-header-col">
                            {(selectedRecords.length === 0) ? "Action" : renderActionCell(undefined, true)}
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {(props.addNewRecord === true) && props.renderCreate(props.envName, props.refetchData)}
                {(props.data.map((row, rowIndex) => <React.Fragment key={`row-${rowIndex}-${row.id}`}>
                    {((actionRecordId.includes(row.id) === true) && (props.displayActionRecord === false)) && <>
                            {(operation === commonCfg.OPERATIONS.UPDATE) &&
                                props.renderUpdate(props.envName, props.refetchData, row)}
                            {(operation === commonCfg.OPERATIONS.DELETE) &&
                                props.renderDelete(props.envName, props.refetchData, row)}
                            {(operation === commonCfg.OPERATIONS.APPLY) &&
                                props.renderApply(props.envName, props.refetchData, row)}
                    </>}
                    {((actionRecordId.includes(row["id"]) === false) || (props.displayActionRecord === true)) && <>
                        <FlexboxGrid justify="space-between" key={`row-${rowIndex}`} className="records-row" style={{ display: "flex", margin: "5px", height: "auto" }}>
                            {(props.showSelectColumn === true) && <FlexboxGrid.Item colspan={1} key={`records-select-column`}>
                                <Checkbox
                                    checked={selectedRecords.includes(row.id)}
                                    value={row.id}
                                    onChange={selectRecordHandler}
                                    style={{
                                        "--rs-checkbox-border": "black"
                                    }}
                                />
                            </FlexboxGrid.Item>}
                            {(props.displayEnv === true) && <FlexboxGrid.Item colspan={props.bookieWidth} key={`col-bookmaker`}>
                                <div className="records-col">
                                    <Stack wrap spacing={6} justifyContent="center">
                                        {renderChildren(rowIndex)}
                                    </Stack>
                                </div>
                            </FlexboxGrid.Item>}
                            {(props.keys.map((key, keyIndex) =>
                                <FlexboxGrid.Item colspan={props.colWidth[keyIndex]} key={`col-${keyIndex}-${row[key]}`}>
                                    <div className="records-col">
                                        {(isBoolean(row[key]) === true) ?
                                            <div>
                                                <Toggle
                                                    checked={row[key]}
                                                    style={{
                                                        "--rs-toggle-checked-bg": "#c73f00",
                                                        "--rs-toggle-checked-hover-bg": "#a83800"
                                                    }}
                                                />
                                            </div>:
                                            (((row[key] === null) || (row[key] === "") || (has(row, key) === false))?
                                                "--" :
                                                (Array.isArray(row[key])) ? renderList(row[key]) :
                                                <Input
                                                    plaintext
                                                    value={(row[key] === 0) ?
                                                                "0" :
                                                                (((Date.parse(row[key]) > 0) &&
                                                                    (typeof row[key] !== "number") &&
                                                                    (row[key].match(/^\d/)) &&
                                                                    (row[key].endsWith('Z'))) ?
                                                                        (new Date(row[key])).toLocaleString() :
                                                                        row[key])
                                                        }
                                                />
                                            )}
                                    </div>
                                </FlexboxGrid.Item> )
                            )}
                            <FlexboxGrid.Item colspan={props.actionWidth} key={`col-action`}>
                                <div className="records-col">
                                    {(actionRecordId.includes(row["id"]) === false) && renderActionCell(row, false)}
                                    {((actionRecordId.includes(row["id"]) === true) && (props.displayActionRecord === true)) && <>
                                        {(operation === commonCfg.OPERATIONS.UPDATE) &&
                                            props.renderUpdate(props.envName, props.refetchData, row)}
                                        {(operation === commonCfg.OPERATIONS.DELETE) &&
                                            props.renderDelete(props.envName, props.refetchData, row)}
                                        {(operation === commonCfg.OPERATIONS.APPLY) &&
                                            props.renderApply(props.envName, props.refetchData, row)}
                                    </>}
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </>}
                </React.Fragment>))}
                </>
            );
        }
    }

    return (
        <>
            {renderTable()}
        </>
    );
}
export default DisplayTable;