import { X, Heart, LoaderCircle, HeartCrack, Pause, Ghost, CircleHelp, ArrowUp, Ban } from 'lucide-react';
import { groupBy, pick, sortBy } from 'lodash';
import { appTypes, gitGroups, appHealthStatus } from "./config";

export const isAppType = (name) => {
    return Object.values(appTypes).includes(name)
};

export const getGroupColour = (name) => {
    switch (name) {
        case gitGroups.NONPROD:
            return "rgb(230, 239,220)";
        case gitGroups.STAGING:
            return "rgb(214, 240, 245)";
        case gitGroups.PRODUCTION:
            return "rgb(233, 201, 201)";
        case gitGroups.WINCORE:
            return "rgba(245, 225, 200, 0.5)";
        default:
            return "rgba(143, 98, 0, 0.5)";
    }
};

export const getEnvColour = (name) => {
    switch (name) {
        case "qat-wincore":
            return "rgba(230, 239,220, 1)";
        case "staging-wincore":
            return "rgba(214, 240, 245, 0.9)";
        case "prod-wincore":
            return "rgba(233, 201, 201, 0.7)";
        default:
            return "white";
    }
};

export const getAppHealthIcon = (name, size=12) => {
    switch (name) {
        case appHealthStatus.HEALTHY:
            return <Heart fill="green" strokeWidth={0} size={size} />;
        case appHealthStatus.PROGRESSING:
            return <LoaderCircle color="#0DADEA" strokeWidth={5} size={size} />;
        case appHealthStatus.DEGRADED:
            return <HeartCrack fill="#E96D76" strokeWidth={0} size={size} />;
        case appHealthStatus.SUSPENDED:
            return <Pause color="#766F94" strokeWidth={2} size={size} />;
        case appHealthStatus.MISSING:
            return <Ghost fill="#F4C030" strokeWidth={0} size={size} />;
        case appHealthStatus.UNKNOWN:
            return <CircleHelp color="#495763" strokeWidth={3} size={size} />;
        case appHealthStatus.OUTOFSYNC:
            return <ArrowUp color="#CC6600" strokeWidth={5} size={size} />;
        case appHealthStatus.SCALEDDOWN:
            return <X color="red" strokeWidth={5} size={size} />;
        default:
            return <Ban color="red" strokeWidth={2} size={size} />;
    }
};

export const createEnvPickerData = (rawData, exclude) => {
    const envData = Object.entries(groupBy(rawData.groups.map(item => {
        return item.environments.map(env => {
            return pick(env, ['name', 'grp_name'])
        })
    }).flat(), "grp_name")).map(item => ({
        label: item[0],
        value: item[0],
        children: sortBy(item[1].map(env => {
            if (exclude.includes(`${item[0]}/${env.name}`)) {
                return undefined
            } else {
                return {
                    label: env.name,
                    value: `${item[0]}/${env.name}`
                }
            }
        }).filter(Boolean), ["label"])
    }))
    return envData
};