import DbAccess from '../../components/DbAccess';
import SessionTimer from '../../components/SessionTimer';
import Header from '../../components/Header';
import { getProjectId } from '../../components/Authentication';
import { projectTypes } from '../../config/app';

const Database = () => {
	return (
		<>
			<SessionTimer />
            <Header />
			<div className='main'>
				<div className='area' style={{ width: "100%" }} >
					{(getProjectId() === projectTypes.CORE) && <DbAccess />}
				</div>
			</div>
		</>
	);
};

export default Database;