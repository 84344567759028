import { Message } from "rsuite";
import Search from "../Search";
import Insert from "../Insert";
import Update from "../Update";
import Records from "../Records";
import { playbooks } from "../../config/playbooks";
import { has } from "lodash";

const PlaybookStep = ({ props }) => {
    let step = 'undefined'
    let disclaimer = <></>
    let stepElement = <></>

    if(props.feedStep === true) {
        step = playbooks[props.playbookId].feedsteps[0]
    } else {
        if(props.autoMode === true) {
            step = playbooks[props.playbookId].autoplay[0]
        } else {
            step = playbooks[props.playbookId].steps[props.stepId]
        }
    }

    if (has(step, "disclaimer") === true) {
        disclaimer = <Message
                        showIcon
                        type="info"
                        style={{
                            "--rs-message-info-icon": "#ffffff",
                            "--rs-message-info-text": "#ffffff",
                            "--rs-message-info-bg": "red",
                            marginTop: "20px"
                        }}
                    >
                            <b>{step["disclaimer"]}</b>
                    </Message>
    }

    switch (step.name) {
        case 'Backfill Competitions':
        case 'Backfill Markets':
        case 'Backfill Matches':
        case 'Backfill Sports':
        case 'Delete feed status':
        case 'Fetch deadlettered messages':
        case 'Fetch sportcast raw data':    
        case 'Get feed status':
        case 'Get race with markets':
        case 'Get race with markets mixed':
        case 'Resub Oddsmatrix':    
        case 'Republish Race':
        case 'Search Card':
        case 'Search Deposit':
        case 'Search Punter':
        case 'Search Withdrawal':
        case 'Sync Venues':
            stepElement = <Search props={{
                                id: step.id,
                                superId: playbooks[props.playbookId].id,
                                feedStep: props.feedStep,
                                feeder: props.feeder,
                                playbookId: props.playbookId,
                                stepId: props.stepId,
                                isHistory: false,
                                showEnv: step.showEnv,
                                excludedEnv: step.excludedEnv,
                                apiUrl: step.apiUrl,
                                searchByData: step.searchByData
                            }}
                        />;
            break;
        case 'Search Card History':
        case 'Search Deposit History':
            stepElement = <Search props={{
                                id: step.id,
                                superId: playbooks[props.playbookId].id,
                                feedStep: props.feedStep,
                                feeder: props.feeder,
                                playbookId: props.playbookId,
                                stepId: props.stepId,
                                isHistory: true,
                                showEnv: step.showEnv,
                                excludedEnv: step.excludedEnv,
                                apiUrl: step.apiUrl,
                                searchByData: step.searchByData
                            }}
                        />;
            break;
        case 'Add market disclaimer to matches':
        case 'Close Open Matches':
        case 'Close Open Races':
        case 'Update Bookie Role':
        case 'Update feed status':
        case 'Update Match Start Time':
        case "Update Withdrawal Request":
            stepElement = <Update props={{
                                id: step.id,
                                superId: playbooks[props.playbookId].id,
                                feedStep: props.feedStep,
                                feeder: props.feeder,
                                playbookId: props.playbookId,
                                stepId: props.stepId,
                                showEnv: step.showEnv,
                                excludedEnv: step.excludedEnv,
                                apiUrl: step.apiUrl,
                                updateData: step.updateData,
                                whereData: step.whereData
                            }}
                        />;
            break;
        case 'Auto Execute Playbook':
        case 'Backfill DWH tables':
        case 'Backfill Open/Active outcomes by sport':
        case 'Backfill Event(s)':
        case 'Backfill Sportscast Competitions':
        case 'Backfill Sportscast Matches':
        case 'Backfill Sportscast Market Groups':
        case 'Backfill Sportscast Markets':
        case 'Backfill Sportscast Propositions':
        case 'Blacklist a Punter':
        case 'Revoke Blacklisted Punter':
        case 'Search Punter Bets':
        case 'Search Blacklisted Punter':
        case 'Search Punter IP Address':
        case 'Bulk Update Punter Flags':               
        case 'Bulk Bookie Creation':
        case 'Create a Bookie':
        case 'Create Bookie Data':
        case 'Convert MBL exception XLSX to JSON':
        case 'Delete Bookie From Firebase Project':
        case 'Grant Time-based Access to PostgreSQL DB':
        case 'Insert MBL exception as JSON':
        case 'Tote & Exotic Manual Settlement':
        case 'Purge deadlettered message':
        case 'Requeue deadlettered message':
        case 'Revoke Time-based Access to PostgreSQL DB':
        case 'Upload Signature Image':
            stepElement = <Insert props={{
                                id: step.id,
                                superId: playbooks[props.playbookId].id,
                                feedStep: props.feedStep,
                                feeder: props.feeder,
                                playbookId: props.playbookId,
                                stepId: props.stepId,
                                postEnv: step.postEnv,
                                showEnv: step.showEnv,
                                excludedEnv: step.excludedEnv,
                                apiUrl: step.apiUrl,
                                insertData: step.insertData
                            }}
                        />;
            break;
        case 'Featured Race Config':
        case 'Featured Match Config':
        case 'Global Offerings':
        case 'Manage API Roles':
        case 'Manage API Users':
        case 'Manage Default MBL Thresholds':
        case 'Quick Links Config':
        case 'Scheduled Price Adjustments':
            stepElement = <Records props={{
                            id: step.id,
                            superId: playbooks[props.playbookId].id,
                            apiUrl: step.apiUrl
                        }}
                    />;
            break;
        default:
            stepElement = <></>;
            break;

    }

    return (
        <>
            {disclaimer}
            {stepElement}
        </>
    )
};

export default PlaybookStep;
