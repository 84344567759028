export const gitGroups = {
    NONPROD: "nonprod",
    STAGING: "staging",
    WINCORE: "wincore",
    PRODUCTION: "production"
}

export const appTypes = {
    BACKENDAPI: "core-backend",
    PUNTERFE: "punter-fe",
    BOOKIEFE: "bookie-fe"
}

export const gitopsStatus = {
    NONE: "none",
    GREEN: "green",
    AMBER: "amber",
    RED: "red"
}

export const appHealthStatus = {
    HEALTHY: "Healthy",
    PROGRESSING: "Progressing",
    DEGRADED: "Degraded",
    SUSPENDED: "Suspended",
    MISSING: "Missing",
    UNKNOWN: "Unknown",
    OUTOFSYNC: "OutOfSync",
    SCALEDDOWN: "ScaledDown",
    ARGOERR: "ARGOERR"
}