module.exports = {
    environments: {
        core: [
            {
                label: 'dev-betcloud',
                type: 'DEV',
                url: 'dev.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'DWH', 'Developers']
            },
            {
                label: 'bat-betcloud',
                type: 'DEV',
                url: 'bat.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'Developers']
            },
            {
                label: 'qat-betcloud',
                type: 'QAT',
                url: 'qat.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'DWH', 'QA-Developers']
            },
            {
                label: 'perf-betcloud',
                type: 'QAT',
                url: 'perf.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'staging-betcloud',
                type: 'QAT',
                url: 'staging.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'hotfix-betcloud',
                type: 'PROD',
                url: 'hotfix.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'betgalaxy-betcloud',
                type: 'LIVE',
                url: 'betgalaxy.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'wellbet-betcloud',
                type: 'LIVE',
                url: 'wellbet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'questbet-betcloud',
                type: 'LIVE',
                url: 'questbet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'betroyale-betcloud',
                type: 'LIVE',
                url: 'betroyale.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'volcanobet-betcloud',
                type: 'LIVE',
                url: 'volcanobet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'fiestabet-betcloud',
                type: 'LIVE',
                url: 'fiestabet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'puntcity-betcloud',
                type: 'LIVE',
                url: 'puntcity.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'puntgenie-betcloud',
                type: 'LIVE',
                url: 'puntgenie.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'betprofessor-betcloud',
                type: 'LIVE',
                url: 'betprofessor.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'sterlingparker-betcloud',
                type: 'LIVE',
                url: 'sterlingparker.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'junglebet-betcloud',
                type: 'LIVE',
                url: 'junglebet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'titanbet-betcloud',
                type: 'PROD',
                url: 'titanbet.com.au',
                roles: ['Platform', 'DB-RO']
            },
            {
                label: 'goldenrush-betcloud',
                type: 'LIVE',
                url: 'goldenrush.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'juicybet-betcloud',
                type: 'LIVE',
                url: 'juicybet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'chromabet-betcloud',
                type: 'PROD',
                url: 'chromabet.com.au',
                roles: ['Platform', 'DB-RO']
            },
            {
                label: 'vikingbet-betcloud',
                type: 'LIVE',
                url: 'vikingbet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'gigabet-betcloud',
                type: 'LIVE',
                url: 'gigabet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'slambet-betcloud',
                type: 'LIVE',
                url: 'slambet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'sugarcastle-betcloud',
                type: 'LIVE',
                url: 'sugarcastle.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'oldgill-betcloud',
                type: 'LIVE',
                url: 'oldgill.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'goldenbet888-betcloud',
                type: 'PROD',
                url: 'goldenbet888.com.au',
                roles: ['Platform', 'DB-RO']
            },
            {
                label: 'buffalobet-betcloud',
                type: 'LIVE',
                url: 'buffalobet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'templebet-betcloud',
                type: 'LIVE',
                url: 'templebet.com.au',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            },
            {
                label: 'bet777-betcloud',
                type: 'PROD',
                url: 'bet777.com.au',
                roles: ['Platform', 'DB-RO']
            },
        ],
        wincore: [
            {
                label: 'dev-winter',
                type: 'DEV',
                url: 'dev.wincore.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'Developers', 'DWH']
            },
            {
                label: 'qat-winter',
                type: 'QAT',
                url: 'qat.wincore.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'QA-Developers', 'DWH']
            },
            {
                label: 'staging-winter',
                type: 'QAT',
                url: 'staging.wincore.betcloud.dev',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'prod-winter',
                type: 'LIVE',
                url: 'prod.wincore.betcloud.online',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders', 'DWH']
            }
        ],
        kafka: [
            {
                label: 'dev-betcloud',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'qat-betcloud',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'prod-betcloud',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders']
            }
        ],
        winter: [
            {
                label: 'dev-betcloud',
                type: 'WINTER',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'qat-betcloud',
                type: 'WINTER',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'prod-betcloud',
                type: 'WINTER',
                roles: ['Platform', 'DB-RO', 'CS', 'CS-RO', 'Finance', 'Traders']
            },
            {
                label: 'dev-oddsmatrix',
                type: 'ODDSMATRIX',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'qat-oddsmatrix',
                type: 'ODDSMATRIX',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'prod-oddsmatrix',
                type: 'ODDSMATRIX',
                roles: ['Platform', 'DB-RO']
            },
            {
                label: 'dev-sportcast',
                type: 'SPORTCAST',
                roles: ['Platform', 'DB-RO', 'Developers', 'QA-Developers']
            },
            {
                label: 'qat-sportcast',
                type: 'SPORTCAST',
                roles: ['Platform', 'DB-RO', 'QA-Developers']
            },
            {
                label: 'prod-sportcast',
                type: 'SPORTCAST',
                roles: ['Platform', 'DB-RO']
            }
        ],
        sreplaybook: [
            {
                label: 'sre-playbook',
                type: 'SRE',
                roles: ['Admin']
            }
        ]
    }
}
