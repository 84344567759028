import React from "react";
import { Avatar, Divider, Stack, Button } from 'rsuite';
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { Navigate, useNavigate } from 'react-router-dom';
import { appCfg } from "../../config/app";
import { isAuthenticated, isAdmin, getUserId, getUserName, getUserPicture, getUserRole } from "../Authentication"
import Logout from "../Logout";
import { playFetch } from "../../lib/playfetch";

const UserDropdown = ({ ...props }) => {
    const navigate = useNavigate();
    const switchRole = () => {
        playFetch("generic/auth/switch/admin")
			.then(data => {
                props.onClose()
                if(data["is_admin"] === true) {
				    const cookieData = CryptoJS.AES.encrypt(
					    JSON.stringify(data),
					    appCfg.ENCRYPT_PASS
				    ).toString();
				    const expires_in_ms = new Date(new Date().getTime() + 60 * 60 * 1000)
                    Cookies.set('admin_token', cookieData, {expires: expires_in_ms})
                } else {
                    Cookies.remove('admin_token')
                }
				navigate("/",  { replace: true })
			});
    };

    if (!isAuthenticated()) {
        return <Navigate to="/" replace />;
    } else {
        return (
            <div>
                <Stack direction="column" alignItems="flex-start" spacing="10px">
                    <b>ACCOUNT</b>
                    <Stack spacing="10px">
                        <Avatar circle src={getUserPicture()} />
                        <Stack direction="column" alignItems="flex-start">
                            <b>{getUserName()}</b>
                            <p>{getUserId()}</p>
                        </Stack>
                    </Stack>
                </Stack>
                {(getUserRole().some(role => ["Platform", "Admin"].includes(role)) === true) && <Button
                    block
                    appearance="subtle"
                    onClick={() => switchRole()}
                    style={{
                        "--rs-btn-subtle-text": "var(--rs-btn-subtle-hover-text)",
                        marginTop: "10px"
                    }}
                >
                    {(isAdmin() === true) ? <>Switch role back</> : <>Switch to Admin role</>}
                </Button>}
                <Divider style={{ marginTop: "10px", marginBottom: "10px"}}/>
                <Logout />
            </div>
        );
    }
};

export default UserDropdown;
